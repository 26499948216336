<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>
    <sidebar-fixed-toggle-button />
    <side-bar
      :background-color="sidebarBackground"
      :short-title="this.entityShortTitle"
      :title="this.entity"
      :logo="this.logo"
    >
      <template slot="links">
        <sidebar-item
          :link="{
            name: $t('sidebar.dashboard'),
            icon: 'tim-icons icon-chart-pie-36',
            path: '/dashboard'
          }"
        >
        </sidebar-item>
        <!-- <sidebar-item
          :link="{ name: $t('sidebar.hardware'), icon: 'tim-icons icon-wifi' }"
        >
          <sidebar-item
            :link="{ name: $t('sidebar.hardwareList'), path: '/hardware' }"
          ></sidebar-item>
          </sidebar-item>
        <sidebar-item
          :link="{
            name: $t('sidebar.map'),
            icon: 'tim-icons icon-world'
          }"
        >
          <sidebar-item
            :link="{ name: $t('sidebar.mapView'), path: '/map' }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: $t('sidebar.mapGeoFenceTool'),
              path: '/map/geofence'
            }"
          ></sidebar-item>
        </sidebar-item>
        <sidebar-item
          :link="{ name: $t('sidebar.notifications'), icon: 'tim-icons icon-notes' }"
        >
          <sidebar-item
            :link="{ name: $t('sidebar.alerts'), path: '/notifications/alerts' }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: $t('sidebar.events'),
              path: '/notifications/events'
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: $t('sidebar.manageNotifications'),
              path: '/notifications/manage'
            }"
          ></sidebar-item>
        </sidebar-item>
        <sidebar-item
          :link="{
            name: $t('sidebar.users'),
            icon: 'tim-icons icon-settings',
            path: '/users'
          }"
        ></sidebar-item> -->
      </template>
    </side-bar>
    <sidebar-share :background-color.sync="sidebarBackground"> </sidebar-share>
    <div class="main-panel" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>
      <router-view name="header"></router-view>

      <div
        :class="{ content: !$route.meta.hideContent }"
        @click="toggleSidebar"
      >
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </zoom-center-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import SidebarShare from "./SidebarSharePlugin";
function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import SidebarFixedToggleButton from "./SidebarFixedToggleButton.vue";
import { ZoomCenterTransition } from "vue2-transitions";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    SidebarFixedToggleButton,
    ZoomCenterTransition,
    SidebarShare
  },
  data() {
    return {
      // logo: this.$store.getters.userEntity.image,
      // entity: this.$store.getters.userEntity.name,
      entity: "MeshPortal",
      sidebarBackground: "primary" //vue|blue|orange|green|red|primary
    };
  },
  computed: {
    entityShortTitle() {
      let matches = this.entity.match(/\b(\w)/g);
      let acro = matches.join("");
      return matches.join("");
    }
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    initScrollbar() {
      let docClasses = document.body.classList;
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        // if we are on windows OS we activate the perfectScrollbar function
        initScrollbar("sidebar");
        initScrollbar("main-panel");
        initScrollbar("sidebar-wrapper");

        docClasses.add("perfect-scrollbar-on");
      } else {
        docClasses.add("perfect-scrollbar-off");
      }
    }
  },
  mounted() {
    this.initScrollbar();
  }
};
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}

.main-panel .zoomIn {
  animation-name: zoomIn95;
}

@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-panel .zoomOut {
  animation-name: zoomOut95;
}
</style>
