import Vue from "vue";
import Vuex from "vuex";
import * as fb from "../firebase";
import router from "../routes/router";
import { logEvent, logError } from "../common-functions";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
const ls = new SecureLS({
  encodingType: "aes",
  encryptionSecret: "puoJxC3dUR9673FMqfgXOIRd1j4mn78f"
});

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userProfile: {},
    userID: "",
    loggedIn: false,
    userEntity: {},
    userDatabase: {},
    darkMode: false, // dark mode disabled by default
  },
  plugins: [
    createPersistedState({
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      }
    })
  ],
  mutations: {
    setUserProfile(state, val) {
      state.userProfile = val;
    },
    setUserEntity(state, val) {
      state.userEntity = val;
    },
    setUserID(state, val) {
      state.userID = val;
    },
    setUserState(state, val) {
      state.loggedIn = val;
      console.log(state.loggedIn);
    },
    setDarkMode(state, enabled) {
      state.darkMode = enabled;
    }
  },
  actions: {
    async logout({ state, commit }) {
      await fb.auth.signOut();

      // Clear userProfile and redirect to the landing page
      let msg = state.userID + " has logged out.";
      logEvent("Authentication - Logout:", msg);
      logEvent("Authentication:", msg);
      commit("setUserState", false);
      commit("setUserProfile", {});
      commit("setUserEntity", {});
      router.push("/").catch(err => err);
    },
    async fetchUserProfile({ commit }, user) {
      commit("setUserState", true);
      commit("setUserID", user.uid);
      try {
        let msg = user.uid + " has logged in.";
        logEvent("Authentication", msg);
        //fetch the user profile
        let usrProfile = await fb.usersCollection.doc(user.uid).get();
        // Set the user profile state
        commit("setUserProfile", usrProfile.data());
      } catch (error) {
        logError("FetchUserProfile", error);
      }
    },
    async fetchUserEntity({ dispatch, state, commit }, user) {
      // fetch the user entity information
      await dispatch("fetchUserProfile", user);
      try {
        const entityID = state.userProfile.entityID;
        const userEntity = await fb.entitiesCollection.doc(entityID).get();
        //committ the entity information
        commit("setUserEntity", userEntity.data());
      } catch (error) {
        //await fb.logger.logEvent("fetchProfileError: " + error);
        logError("FetchEntity", error);
      }
      if (router.currentRoute.path === "/login") {
        router.push("/dashboard");
      }
    },
    enableDarkMode ({ dispatch, state, commit }, mode) {
      commit('setDarkMode', mode)
    }
  },
  getters: {
    loggedInState: state => state.loggedIn,
    userProfile: state => state.userProfile,
    userEntity: state => state.userEntity,
    userID: state => state.userID,
    userDatabase: state => state.userEntity.database.name,
    darkMode: state => state.darkMode
  },
  modules: {}
});
