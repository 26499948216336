import DashboardLayout from "src/pages/Layout/DashboardLayout.vue";
import UnitDashboardLayout from "src/pages/Layout/UnitDashboardLayout.vue";
import AuthLayout from "src/pages/Pages/AuthLayout.vue";
import LandingPageLayout from "src/pages/Pages/LandingPageLayout.vue";
// GeneralViews
import NotFound from "src/pages/GeneralViews/NotFoundPage.vue";

// Dashboard pages
const Dashboard = () =>
  import(/* webpackChunkName: "dashboard" */ "src/pages/Dashboard/Dashboard.vue");
const UnitDashboard = () =>
  import(/* webpackChunkName: "unitDashboard" */ "src/pages/Dashboard/UnitDashboard.vue");
const EventDashboard = () =>
  import(/* webpackChunkName: "unitDashboard" */ "src/pages/Dashboard/EventsDashboard.vue");

// Pages
const User = () =>
  import(/* webpackChunkName: "pages" */ "src/pages/Pages/UserProfile.vue");
const Login = () =>
  import(/* webpackChunkName: "pages" */ "src/pages/Pages/Login.vue");
const Register = () =>
  import(/* webpackChunkName: "pages" */ "src/pages/Pages/Register.vue");
const PasswordReset = () =>
  import(/* webpackChunkName: "pages" */ "src/pages/Pages/PasswordReset.vue");
const RTL = () =>
  import(/* webpackChunkName: "pages" */ "src/pages/Pages/RTLPage.vue");
const Home = () =>
  import(/* webpackChunkName: "pages" */ "src/pages/Pages/Home.vue");

let authPages = {
  path: "/",
  component: AuthLayout,
  name: "Authentication",
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login
    },
    {
      path: "/register",
      name: "Register",
      component: Register
    },
    {
      path: "/reset-password",
      name: "Password Reset",
      component: PasswordReset
    }
  ]
};

let landingPage = {
  path: "/",
  component: LandingPageLayout,
  name: "Landing Page",
  children: [
    {
      path: "/home",
      name: "Meshcomm Engineering",
      component: Home
    }
  ]
};

let unitDashboard = {
  path: "/",
  component: UnitDashboardLayout,
  name: "Unit Portal",
  meta: {
    requiresAuth: true
  },
  children: [
    {
      path: "dashboard/:unitID",
      name: "Unit Dashboard",
      components: { default: UnitDashboard }
    },
    {
      path: "dashboard/:unitID/events",
      name: "Unit Events Dashboard",
      components: { default: EventDashboard }
    }
  ]
};

const routes = [
  {
    path: "/",
    redirect: "/home",
    name: "Home"
  },
  authPages,
  landingPage,
  unitDashboard,
  {
    path: "/",
    component: DashboardLayout,
    name: "MeshPortal",
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        components: { default: Dashboard }
      }
     ]
  },
  { path: "*", component: NotFound }
];

export default routes;
