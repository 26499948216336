<template>
  <router-view></router-view>
</template>

<script>
import { mapGetters } from "vuex";
import { bus } from "@/util/bus";

export default {
  methods: {
    initializeLayout() {
      if (!this.$rtl.isRTL) {
        // Just make sure rtl css is off when we are not on rtl
        this.$rtl.disableRTL();
      }
    },
    initializeColorMode() {
      // Set document body class based on color mode selected
      const docClasses = document.body.classList
      if (this.darkMode) {
        docClasses.remove('white-content')
      } else {
        docClasses.add('white-content')
      }
    }
  },
  watch: {
    darkMode: function (enabled, last) {
      if (enabled !== last) {
        // Re-init
        this.initializeColorMode()

        // Alert components to update theme
        bus.$emit('darkModeToggled', enabled)
      }
    }
  },
  created () {
    this.initializeColorMode()
  },
  mounted() {
    this.initializeLayout()
  },
  computed: {
    ...mapGetters({
      darkMode: 'darkMode'
    })
  }
};
</script>

<style>
html, body {
  overflow-x: hidden;
}
</style>
