<template>
  <footer class="footer">
    <div class="container-fluid">
      <ul class="nav">
       <li class="nav-item">
          <a
            href="https://www.meshcommengineering.com"
            target="_blank"
            rel="noopener"
            class="nav-link"
          >
            Meshcomm Engineering
          </a>
        </li>
        <li class="nav-item">
          <a
            href="https://www.meshcommengineering.com"
            target="_blank"
            rel="noopener"
            class="nav-link"
          >
            About Us
          </a>
        </li>
        <li class="nav-item">
          <a
            href="https://meshcommengineering.com/contact-us"
            target="_blank"
            rel="noopener"
            class="nav-link"
          >
            Contact Us
          </a>
        </li>
      </ul>
      <div class="copyright">
        &copy; {{ year }}
        <a href="https://meshcommengineering.com" target="_blank"> Meshcomm Engineering</a>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style></style>
