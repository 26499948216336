<template>
  <card class="card-stats" :show-footer-line="true">
    <div class="row">
      <div class="col-2" v-if="$slots.icon || icon">
        <div class="info-icon text-center" :class="`icon-${type}`">
          <slot name="icon"> <i :class="icon"></i> </slot>
        </div>
      </div>
      <div class="col-7" v-if="$slots.content || title || subTitles">
        <div class="numbers">
          <h2 v-if="title" class="card-title">{{ title }}</h2>
          <slot>
            <p v-for="(subTitle, index) in subTitles" :key="index" class="card-category">
              <strong>{{ subTitle.name }}:</strong> {{ subTitle.value }} {{ subTitle.unit || "" }}
            </p>
          </slot>
        </div>
      </div>
    </div>
    <div class="stats" slot="footer" v-if="$slots.footer">
      <slot name="footer"></slot>
    </div>
  </card>
</template>
<script>
import Card from './Card.vue';

export default {
  name: 'stats-card',
  components: {
    Card
  },
  props: {
    type: {
      type: String,
      default: 'primary'
    },
    icon: String,
    title: String,
    subTitles: Array
  }
};
</script>
<style></style>
