import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/analytics";
import "firebase/functions";

// mesh-db firebase init
const firebaseConfig = {
  apiKey: "AIzaSyDjDI29gzl53bxDicTXvKlKrwDnyTDL1dQ",
  authDomain: "mesh-portal-stg.firebaseapp.com",
  databaseURL: "https://mesh-portal-stg.firebaseio.com",
  projectId: "mesh-portal-stg",
  storageBucket: "mesh-portal",
  messagingSenderId: "650325974129",
  appId: "1:650325974129:web:0545fd2677aaa1ef2a4fd0",
  measurementId: "G-VH5DMPX9RX"
};

// dev-db firebase init
// const firebaseConfig = {
//   apiKey: "AIzaSyBrifILObHZWloc3hRXN44Oww5msqPE_rg",
//   authDomain: "awesome-db-ccc92.firebaseapp.com",
//   projectId: "awesome-db-ccc92",
//   storageBucket: "awesome-db-ccc92.appspot.com",
//   messagingSenderId: "406310553479",
//   appId: "1:406310553479:web:d7ef835bf3d39a9d763522",
//   measurementId: "G-6MSP0MJ0LS"
// };
firebase.initializeApp(firebaseConfig);
firebase.analytics();

//utils
const meshDb = firebase.firestore();
const auth = firebase.auth();
const logger = firebase.analytics();
const meshFunctions = firebase.functions();
const firestore = firebase.firestore;

// database collection references
const usersCollection = meshDb.collection("users");
const entitiesCollection = meshDb.collection("entities");

export { auth, logger, meshDb, usersCollection, entitiesCollection, meshFunctions, firestore };
