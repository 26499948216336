/*!

 =========================================================
 * Vue Black Dashboard PRO - v1.2.3
 =========================================================

 * Product Page: https://www.creative-tim.com/product/vue-black-dashboard-pro
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)

 * Coded by Creative Tim

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import Vue from "vue";
import VueRouter from "vue-router";
import { auth } from "./firebase";
import { API_KEY } from "@/pages/Maps/API_KEY"
import { Loader } from "google-maps";
import RouterPrefetch from "vue-router-prefetch"
import DashboardPlugin from "./plugins/dashboard-plugin";
import { Loading } from "element-ui";
import VueWaypoint from "vue-waypoint";
import App from "./App.vue";

// Service worker
import "./registerServiceWorker"

// Wait for Google Maps to load before mounting app...
(async () => {
  const loader = new Loader(API_KEY)
  const google = await loader.load()

  window.google = google

  // plugin setup
  Vue.use(DashboardPlugin);
  Vue.use(VueRouter);
  Vue.use(RouterPrefetch);
  Vue.use(Loading.directive);
  Vue.use(VueWaypoint)

  /* eslint-disable no-new */
  let app;
  auth.onAuthStateChanged(user => {
    if (!app) {
      // Set up the Vue app
      app = new Vue({
        router: require("./routes/router").default,
        store: require("./store").default,
        i18n: require("./i18n").default,
        render: h => h(App)
      });

      // Mount the app
      app.$mount("#app");
    }

    if (user) {
      app.$store.dispatch("fetchUserEntity", user);
    }
  });
})()
