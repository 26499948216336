import { logger } from "./firebase";

const logError = (prefix, error) => {
  if (!error.code) {
    error.code = "";
  }
  if (!error.message) {
    error.message = "";
  }
  if (!error.stack) {
    error.stack = "";
  }
  logger.logEvent(prefix + ": Errorcode: " + error.code + ": " + error.message);
  logger.logEvent(prefix + ": StackTrace: " + error.stack);
};

const logEvent = (prefix, message) => {
  logger.logEvent(prefix + ": " + message);
};

export { logError, logEvent };
